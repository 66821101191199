import React from "react"
import Layout from "../components/Layout/Layout"
import PageTransitionContainer from "../components/PageTransitionContainer"
import Form from "../components/Form"
import Seo from "../components/Seo"
import { sections } from "../data/form-buy-home"

const BuyAHome = (props) => {
  return (
    <PageTransitionContainer>
      <Layout location={props.location} page="buy-a-home">
        <Seo
          title="Buying A Home | Mortgage | Ratelock"
          location={props.location}
        />
        <Form
          idToUse="buy"
          label="Get a Mortgage To Buy A Home at the Lowest Rates"
          sections={sections}
        />
      </Layout>
    </PageTransitionContainer>
  )
}

export default BuyAHome
