import React from "react"
import {
  faFileAlt,
  faBinoculars,
  faCalendarCheck,
  faCalendarWeek,
  faCalendarPlus,
  faCalendar,
  faMoneyBill,
  faHome,
  faGlobeAmericas
} from "@fortawesome/free-solid-svg-icons"
export const sections = [
  {
    name: "Search",
    questions: [
      {
        label: "When are you planning to make your next purchase?",
        type: "options",
        buttons: [
          "I have signed an agreement",
          "I have looked at a few properties",
          "Next 30 days",
          "Next 1 to 3 months",
          "Next 3 to 6 months",
          "6+ Months"
        ],
        iconButtons: [
          faFileAlt,
          faBinoculars,
          faCalendarCheck,
          faCalendarWeek,
          faCalendarPlus,
          faCalendar
        ],
        stepHelp:
          "Getting to know your timeline lets us provide you with the best experience."
      },
      {
        label: "Are you a first-time buyer?",
        type: "options",
        buttons: ["Yes", "No"],
        stepHelp:
          "First-time home buyers may be eligible for incentives and tax rebates."
      }
    ]
  },
  {
    name: "Your Purchase Cost",
    title: "Let's learn about your new home",
    questions: [
      {
        label: "Which type of property are you planning on buying?",
        type: "options",
        buttons: ["Primary Home", "Investment Property", "Vacation Home"],
        iconButtons: [faHome, faMoneyBill, faGlobeAmericas],
        stepHelp: "Mortgage rates differ based on property types."
      },
      {
        label: "What will be the purchase price of the property?",
        type: "purchaseAmount",
        min: {
          amount: 100000,
          error:
            "Please enter a value above 100,000. We're unable to provide you with offers for a mortgage value less than $100,000."
        },
        max: {
          amount: 5000000,
          error:
            "Please enter a value between 100K - 5M. We're unable to provide you with offers for a mortgage value greater than $5,000,000."
        },
        stepHelp:
          "Your purchase price is determined by various factors and the most significant among them is the downpayment."
      },
      {
        label: "How much downpayment do you have to purchase this property?",
        type: "downpayment",
        stepHelp:
          "You must provide at least 5% down payment on the desired property. If your downpayment is under 20% of the purchase price of the property, you'll be required to purchase the default insurance."
      }
    ]
  },
  {
    name: "Finance And Credit",
    questions: [
      {
        label: "How is your Credit Score?",
        type: "options",
        buttons: [
          "Excellent (800-720)",
          "Great (720- 680)",
          "Good (680-600)",
          "Fair (Below 600)"
        ]
      },
      {
        label: "What is your Annual Household Income?",
        type: "amount"
      }
    ]
  },
  {
    name: "Almost There",
    title: "Just a few more questions to go",
    questions: [
      {
        label: "What term do you prefer?",
        type: "options",
        buttons: ["5 Years", "3 Years", "1 Year"]
      },
      {
        label: "What kind of rates do you prefer?",
        type: "options",
        buttons: ["Fixed", "Variable", "Not Sure"],
        stepHelp: (
          <span>
            Monthly payments remain the same with <strong>fixed rate</strong>{" "}
            mortgages. <br />
            <br />
            Mortgage payments may vary slightly every month with{" "}
            <strong>variable rate</strong> mortgages.
          </span>
        )
      }
    ]
  },
  {
    name: "Last Step",
    lastStepText:
      "After comparing more than 40 vendors we found the lowest rates for you.",
    completeText: "Where do we send the quote?",
    isLastStep: true
  },
  {
    name: "Success" // reserved keyword
  }
]
